import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  Typography,
  FormControl,
  NativeSelect,
  FormHelperText,
} from '@material-ui/core';

import { useTheme, makeStyles } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import { gradientColors, gcSuggestions } from 'client/utils/colorutil';
import { AssigneeCntByCPC } from 'client/graphql/query';
import useWindowDimensions from 'client/utils/window';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function CountByAssignee(props) {
  const { width } = useWindowDimensions();
  const classes = useStyles();
  const theme = useTheme();
  const [count, setCount] = useState(10);

  const { cpc } = props;
  const { loading, error, data: cpcData } = useQuery(AssigneeCntByCPC, {
    variables: { cpc },
  });

  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  let { assigneeCntByCPC } = cpcData;
  assigneeCntByCPC = assigneeCntByCPC.slice(0, count*2); // temp, because brings too many data

  let cntByAssignee = {};
  assigneeCntByCPC.forEach(({ assignee, count }) => {
    // assignee 이름 표준화
    // assignee 이름 표준화
    if (assignee.includes('LG 에너지솔루션') ||  assignee.includes('엘지에너지솔루션') || assignee.includes('LG EnergySolution') || assignee.includes('LG Energy Solution') || assignee.includes('Lg Energy Solution')) {
      assignee = 'LG Energy Solution';
    }
    else if (assignee.includes('삼성에스디아이 주식회사') ||  assignee.includes('Samsung Sdi')||  assignee.includes('Samsung SDI') || assignee.includes('三星SDI')|| assignee.includes('三星Sdi')) {
      assignee = 'Samsung Sdi Co., Ltd.';
    }
    else if (assignee.includes('주식회사 엘지화학') ||assignee.includes('LG化学')||assignee.includes('Lg化学') ||  assignee.includes('LG Chem') || assignee.includes('Lg Chem')) {
      assignee = 'LG Chem, Ltd.';
    }
    else if (assignee.includes('Samsung Electronics') ||  assignee.includes('삼성전자')) {
      assignee = 'Samsung Electronics';
    }
    else if (assignee.includes('Lg Electronics') ||  assignee.includes('엘지전자')) {
      assignee = 'Lg Electronics';
    }
    else if (assignee.includes('현대자동차')  ||  assignee.includes('Hyundai Motor')) {
      assignee = 'Hyundai Motor';
    }
    // 표준화된 assignee 이름으로 count 누적
    if (cntByAssignee[assignee]) {
      cntByAssignee[assignee] += count;
    } else {
      cntByAssignee[assignee] = count;
    }
  });
  
  let assigneeEntries = Object.entries(cntByAssignee).slice(0, count);
  let assigneeList = assigneeEntries.map(([assignee, _]) => assignee);
  let cntList = assigneeEntries.map(([_, count]) => count);
  let backgroundColorList = [];

  const overall = cntList.reduce((acc, val) => (acc += val), 0);
  assigneeList = assigneeList.map((val, idx) => {
    const addStr = `: ${((cntList[idx] / overall) * 100).toFixed(1)}%`;
    return val + addStr;
  });

  const { startColor, endColor } = gcSuggestions[0];
  backgroundColorList = gradientColors(
    startColor,
    endColor,
    cntList.length
  );

  const data = {
    datasets: [
      {
        label: 'Competitors',
        data: cntList,
        backgroundColor: backgroundColorList,
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      },
    ],
    labels: assigneeList,
  };

  const options = {
    legend: {
      display: width > 550,
      position: width > 960 && count <= 15 ? 'right' : 'bottom',
    },
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
  };

  const handleChange = (event) => {
    setCount(event.target.value);
  };

  return (
    <>
      <Typography variant='body2'>
        Similar patents by competitors (analyzed by technologies)
      </Typography>
      <FormControl className={classes.formControl}>
        <NativeSelect
          value={count}
          onChange={handleChange}
          inputProps={{
            name: 'value',
            id: 'value-helper',
          }}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </NativeSelect>
        <FormHelperText>Select number of competitors</FormHelperText>
      </FormControl>
      <br />
      <Doughnut data={data} options={options} />
    </>
  );
}

export default CountByAssignee;
