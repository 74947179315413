import React from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from './client/serviceWorker';
import App from './client/App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();
