import React from 'react'
import { CardMedia } from '@material-ui/core';
import useStyles from './RankingIcon.style';

const RankingIcon = () => {
    const classes = useStyles();

    return (
        <>
            <CardMedia className = {classes.ranking} component="img" alt="ranking" image='/images/ranking.png'/>
        </>
    );
} 

export default RankingIcon