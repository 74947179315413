import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1)
  },
  
  tableMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
 
  wordCloud: {
    marginTop: '10px'
  }
}));

export default useStyles;