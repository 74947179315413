import React, {useEffect} from 'react';
import { useQuery } from '@apollo/client';
import { Patents as PatentsQuery } from 'client/graphql/query';
import { FixedHeaderTable } from 'client/components';
import {LinearProgress} from '@material-ui/core';

function PatentsTable({ assignee, language }) {
    const { loading, error, data, refetch } = useQuery(PatentsQuery, {
      variables: { assignee },
      errorPolicy: 'all',
    });
  
    useEffect(() => {
      if (error) {
        setTimeout(() => {
          refetch();
        }, 3000)
      }
    },[error, refetch]);

    if (assignee === '') return null;
    if (loading) return <LinearProgress />;
    if (error) return <p>Data loading...</p>;
    
    return <FixedHeaderTable rows={data.patents} topViewedComponent = {false} language={language}/>;
  }

export default PatentsTable