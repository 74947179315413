import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  Grid,
  TextField,
  Button,
  LinearProgress,
  Typography,
} from '@material-ui/core';

import { useNavigate, useLocation } from 'react-router-dom';
import { infoByCPC } from 'client/graphql/query';
import { GetCPCsByID } from './components';
import useStyles from './CPC.style';
import Dialog from './components/Dialog';

function CPC() {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const defaultPath = '/cpc/';
  const pathname = location.pathname;
  const defaultID = pathname.includes(defaultPath)
    ? pathname.split(defaultPath).join('')
    : 'US-10524228-B2';

  const [values, setValues] = useState({
    patentID: defaultID,
    patentIDSub: defaultID,
  });

  const { loading, error, data } = useQuery(infoByCPC, {
    variables: { id: values.patentID },
  });

  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  const cpcInfo = data.infoByCPC[0];

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const clickButtonHandler = () => {
    setValues({
      ...values,
      patentID: values.patentIDSub,
    });
    console.log(values.patentID);
    navigate(`/cpc/${values.patentID}`);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.cpc}>
        <Grid item lg={3} md={3} xl={3} xs={12}>
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            <TextField
              fullWidth
              helperText='Please specify the patent number'
              label='Patent id / number'
              margin='dense'
              name='patentIDSub'
              defaultValue={values.patentID}
              required
              variant='outlined'
              onChange={handleChange}
            />
            <Button
              variant='contained'
              className={classes.searchButton}
              color='primary'
              size='small'
              onClick={clickButtonHandler}
            >
              Search
            </Button>
          </Grid>
          {cpcInfo && (
            <Grid key='infoGrid' className={classes.infoGrid}>
              <Typography key='patentInfo' variant='body2'>
                Patent Information
              </Typography>
              <Typography key='title' variant='caption'>
                Title: {cpcInfo.title}
              </Typography>
              <Typography key='assignee' variant='caption'>
                Assignee: {cpcInfo.assignee}
              </Typography>
              <br />
              <Typography key='inventor' variant='caption'>
                Inventor:
              </Typography>

              <br />
              {cpcInfo.inventor.map((item, index) => {
                return (
                  <div key={item + index}>
                    <Typography variant='caption'>
                      ({index + 1}) {item}
                    </Typography>
                    <br />
                  </div>
                );
              })}
              <Typography key='publication_date' variant='caption'>
                Publication Date: {cpcInfo.publication_date}
              </Typography>
              <Dialog cpcCodes={cpcInfo.cpc} />
            </Grid>
          )}
        </Grid>
        <Grid item lg={9} md={9} xl={9} xs={12}>
          <GetCPCsByID patentID={values.patentID} />
        </Grid>
      </Grid>
    </div>
  );
}

CPC.propTypes = {
  
};

export default CPC;
