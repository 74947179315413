import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  TextField,
  IconButton,
  Grid,
  Typography,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Avatar, Select, MenuItem } from '@mui/material';


import { CompaniesWithIcon } from 'client/definitions/Companies.define';
import { CompanyIcon } from 'client/icons';

import { PatentsTable, PatentChart, PatentBar } from './components';
import useStyles from './Companies.style';


function Companies() {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
 
  const navigate = useNavigate();
  const location = useLocation();
  let { search } = location;
  search = search.replace('?company=', '');
  search = decodeURIComponent(search);

  const [assignee, setAssignee] = useState(search);
  const [text, setText] = useState('');
  const [useDataAnalysis, setUseDataAnalysis] = useState(false);
  const [language, setLanguage] = useState("English");

  useEffect(() => {
    if (search) {
      setAssignee(search);
    }
  }, [search]);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const changeHandler = (e) => {
    setText(e.target.value);
  };

  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      setAssignee(text);
      navigate(`/companies?company=${text}`);
      setUseDataAnalysis(false);
    }
  };

  const handleButtonClick = (company) => {
    setAssignee(company);
    navigate(`/companies?company=${company}`);
    setUseDataAnalysis(false);
  }

  const onSearchClick = () => {
    setText('')
    setAssignee(text);
    navigate(`/companies?company=${text}`);
    setUseDataAnalysis(false);
  };

  // async function reGenerate() {
  //     // Google Cloud Functions의 URL입니다.
  //   setLoading(true);
  //   setAnalysis([]);
  //   console.log("language: ",  language);
  //   const functionUrl = `https://us-central1-${process.env.REACT_APP_GCP_PROJECT_ID}.cloudfunctions.net/function-scraper`;
  //   //const functionUrl = `http://localhost:8080/`;
  //   // POST 요청을 보내서 질문을 서버로 전송합니다.
  //   try {
  //   const userid = email;
  //   const response = await fetch(functionUrl, {
  //   method: 'POST',
  //   headers: {
  //   'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({ patid, userid, language, model: process.env.REACT_APP_OPENAI_GPT_MODEL, assignee, date, title }),
  //   });

  //   // 응답을 처리합니다. (예: 성공 메시지를 표시)
  //   if (response.ok) {
  //   console.log('Question submitted successfully');
  //   } else {
  //   console.log('Failed to submit question');
  //   }
  //   } catch (error) {
  //   console.error('There was a problem with the request', error);
  //   }
  // }

  return (
    <Grid className={classes.root} container spacing={4}>
      <Grid item className={classes.search} xs={12}>
        <TextField id="outlined-basic" 
                  className={classes.inputInput}
                  value={text}
                  label="Search Company" 
                  variant="outlined" 
                  onChange={(e) => changeHandler(e)}
                  onKeyDown={(e) => keyDownHandler(e)}/>
         <IconButton className={classes.searchButton} onClick={onSearchClick}>
          <SearchIcon />
        </IconButton>
      </Grid>
      
      <Grid className={classes.companies} container spacing={3}>
        {
          Object.entries(CompaniesWithIcon).map(([iconName, companyName], i) => {
            if (!(isMobile && i >= 8)) {
              return (
                <Grid key={iconName} className={classes.company} item xs={3} sm={2}>
                  <CompanyIcon onClick={(e) => handleButtonClick(companyName)} image={`/images/companies/${iconName}.png`} />
                </Grid>
              )
            }
            else {
              return null;
            }
            
          })
        }
      </Grid>
        
      {search &&
      <>
        <Grid item xs={12}>
        <Grid sx={{marginTop:5}}>
          <Tooltip title="Language of AI analysis (GPT-4)">
            <Select sx ={{}}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={language}
              label="Language"
              onChange={handleChange}
            >
                <MenuItem value={"Korean"}>
                  <Avatar src="/images/icons/south-korea.png"/>
                </MenuItem>
                <MenuItem value={"English"}>
                  <Avatar src="/images/icons/america-flag.png"/>
                </MenuItem>
                <MenuItem value={"Spanish"}>
                  <Avatar src="/images/icons/spanish-language.png"/>
                </MenuItem>
                <MenuItem value={"Japanese"}>
                  <Avatar src="/images/icons/japan.png"/>
                </MenuItem>
                <MenuItem value={"Chinese"}>
                  <Avatar src="/images/icons/china.png"/>
                </MenuItem>
            </Select>
          </Tooltip>
            {/* {!loading && <AutorenewIcon onClick = {reGenerate} color="primary" sx = {{cursor: 'pointer', marginLeft: 10}}/>} */}
          </Grid>
          <PatentsTable assignee={assignee} language={language}/>
        </Grid>

        <>
        {useDataAnalysis ? <>
              <Grid item xs={12}>
                <PatentBar assignee={assignee} />
              </Grid>
              <Grid item xs={12}>
                {/* <Typography variant='body2'>Patents filed by {assignee}</Typography> */}
                <Typography variant='caption'>
                  (Filed by EU(europe) are divided seperately to each country)
                </Typography>
                <PatentChart assignee={assignee} />
              </Grid>
            </> : 
          <IconButton onClick={() => setUseDataAnalysis(!useDataAnalysis)}>
            <AssessmentIcon color="primary"/>
          </IconButton>}
        </>
      </> 
      }
    </Grid>
  );
}

export default Companies;
