import React from 'react';
import Chart from 'react-google-charts';

export default function GoogleGeoChart({ width, height, chartData }) {
  return (
    <Chart
      width={width}
      height={height}
      chartType='GeoChart'
      data={chartData}
      // Note: you will need to get a mapsApiKey for your project.
      // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
      mapsApiKey={process.env.FIREBASE_API_KEY}
      rootProps={{ 'data-testid': '1' }}
    />
  );
}
