import React, { useState } from 'react';
// import { useQuery } from '@apollo/client';
import {
  Grid,
  Typography,
  FormControl,
  NativeSelect,
  FormHelperText,
} from '@material-ui/core';
// import capitalize from 'client/utils/stringutil';
// import { TitlesByCPCs } from 'client/graphql/query';
import { CountByAssignee } from './components';
import useStyles from './Trends.style';

const hotTrendsMap = [
  {
    name: 'Artificial Intelligence',
    cpc: 'G06F|G06K|H04N|G10L|G06T|A61B|H04M|G01N|H04R|G06N',
  },
  {
    name: 'Conversational AI',
    cpc: 'G06N5/02',
  },
  {
    name: '3D Printing',
    cpc: 'B33Y',
  },
  {
    name: 'Electric Vehicle Battery',
    cpc: 'H01M10',
  },
  { name: 'Autonomous Driving', cpc: 'G05D|G08G|B60L|B60K|B60W|B60W|G01S' },
  //   { name: 'IOT', cpc: 'G06Y10' },
  //   { name: 'Artificial Intelligence', cpc: 'G06Y10' },
  //   { name: 'Artificial Intelligence', cpc: 'G06Y10' },
];


export default function Trends() {
  const classes = useStyles();
  const [keyword, setKeyword] = useState(hotTrendsMap[0].name);
  const [cpc, setCpc] = useState(hotTrendsMap[0].cpc);

  const handleChange = (event) => {
    setKeyword(event.target.value);
    const idx = hotTrendsMap.findIndex(
      ({ name }) => name === event.target.value
    );
    setCpc(hotTrendsMap[idx].cpc);
  };

  return (
    <>
      <Grid className={classes.root}>
        <Grid>
          <Typography variant='body2'>Choose Hot Trends</Typography>
          <FormControl>
            <NativeSelect
              value={keyword}
              onChange={handleChange}
              inputProps={{
                name: 'state',
                id: 'value-helper',
              }}
            >
              {hotTrendsMap.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>Hot technology trends</FormHelperText>
          </FormControl>
        </Grid>
        <Grid style={{ marginTop: '20px' }}>
          <CountByAssignee cpc={cpc} />
        </Grid>
        {/* <Grid style={{ marginTop: '20px' }}>
          {cpc.split('|').map((x) => (
            <Typography>{x}</Typography>
          ))}
        </Grid> */}
        <br />
        <br />
        <Typography variant='body2'>
          Analyzed by patents containing cpcs below
        </Typography>
        <Typography variant='caption'>{cpc.split('|').join(', ')}</Typography>
      </Grid>
    </>
  );
}
