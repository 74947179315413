import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Grid,
  Typography,
  FormControl,
  NativeSelect,
  FormHelperText,
  LinearProgress,
} from '@material-ui/core';
import { FixedHeaderTable } from 'client/components';
import { Patents as PatentsQuery } from 'client/graphql/query';

export default function TableComponent({ companyList, cpc }) {
  const [company, setCompany] = useState(companyList[0]);
  const { loading, error, data } = useQuery(PatentsQuery, {
    variables: { assignee: company, cpcs: cpc },
  });

  if (cpc === '') return null;
  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  const handleChange = (event) => {
    setCompany(event.target.value);
  };
  return (
    <>
      <Grid style={{ marginBottom: '20px' }}>
        <Typography variant='body2'>
          Choose Company for Patents with Hot Trend
        </Typography>
        <FormControl>
          <NativeSelect
            value={company}
            onChange={handleChange}
            inputProps={{
              name: 'state',
              id: 'value-helper',
            }}
          >
            {companyList.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText>Hot technology trends</FormHelperText>
        </FormControl>
      </Grid>
      <FixedHeaderTable rows={data.patents} />
    </>
  );
}
