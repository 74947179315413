// RULE!: Please write English company name first, other language's name second, ...

export const CompaniesWithIcon = {
    microsoft: 'microsoft|마이크로소프트', 
    google: 'google|구글', 
    apple: 'apple inc|애플', 
    amazon: 'amazon|아마존', 
    facebook: 'facebook|페이스북', 
    nvidia: 'nvidia|엔비디아', 
    amd: 'advanced micro devices', 
    ibm: 'international business machines',
    samsung: "samsung electronics|삼성전자", 
    hyundai: 'hyundai motor|현대자동차',  
    sk: 'sk hynix|sk 하이닉스',
    lg: 'lg electronics|엘지전자',
  }
  
/*
const companiesMap = {
    "hyundai motor" : ["hyundai motor", "현대자동차"],
    "현대자동차" : ["hyundai motor", "현대자동차"],
    
    "samsung electronics" : ["samsung electronics", "삼성전자"],
    "삼성전자" : ["samsung electronics", "삼성전자"],
    
    "microsoft" : ["microsoft", "마이크로소프트"],
    "마이크로소프트" : ["microsoft", "마이크로소프트"],
    
    "google" : ["google", "구글"],
    "구글" : ["google", "구글"],
    
    "apple" : ["apple inc", "애플"],
    "애플" : ["apple inc", "애플"],
    
    "amazon" : ["amazon", "아마존"],
    "아마존" : ["amazon", "아마존"],
    
    "facebook" : ["facebook", "페이스북"],
    "페이스북" : ["facebook", "페이스북"],
    
    "nvidia" : ["nvidia", "엔비디아"],
    "엔비디아" : ["nvidia", "엔비디아"],
    
    "advanced micro devices" : ["advanced micro devices"],
    "amd" : ["advanced micro devices"],
    
    "ibm" : ["international business machines"],
    "international business machines" : ["international business machines"],

    "lg" : ["lg electronics", "엘지전자"],
    "엘지전자" : ["lg electronics", "엘지전자"],
    "lg electronics" : ["lg electronics", "엘지전자"],

    "sk hynix" : ["sk hynix", "sk 하이닉스"],
    "sk" : ["sk hynix", "sk 하이닉스"],
    "sk 하이닉스" : ["sk hynix", "sk 하이닉스"],
}

*/