import React from 'react';
import WordCloudCompany from './components/WordCloudCompany';
import { Grid } from '@material-ui/core';
import useStyles from './Ranking.style';


const Ranking = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <WordCloudCompany />
    </Grid>
  );
};

export default Ranking;
