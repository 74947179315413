import React from 'react';

//import DescriptionIcon from '@mui/icons-material/Description';
//import PieChartIcon from '@mui/icons-material/PieChart';
//import TextFieldsIcon from '@mui/icons-material/TextFields';
//import PortraitIcon from '@mui/icons-material/Portrait';
// import ChatIcon from '@mui/icons-material/Chat';
import { RankingIcon } from 'client/icons'
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarsIcon from '@mui/icons-material/Stars';
import BusinessIcon from '@mui/icons-material/Business';
// import SettingsIcon from '@mui/icons-material/Settings';
// import WallpaperIcon from '@mui/icons-material/Wallpaper';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import PeopleIcon from '@mui/icons-material/People';

export const pageList = [
  {
    title: 'Top Viewed',
    href: '/news',
    icon: <AssessmentIcon />,
  },
  {
    title: 'Companies',
    href: '/companies',
    icon: <BusinessIcon />,
  },
  {
    title: 'Competitors',
    href: '/cpc',
    icon: <AssessmentIcon />,
  },
  {
    title: 'Trends',
    href: '/trends',
    icon: <StarsIcon />,
  },
  // {
  //   title: 'Patent',
  //   href: '/patent',
  //   icon: <DescriptionIcon />,
  // },
  {
    title: 'Ranking',
    href: '/ranking',
    icon: <RankingIcon />,
  },
  // {
  //   title: 'Chat',
  //   href: '/chat',
  //   icon: <ChatIcon />,
  // },

 
 
  // {
  //   title: 'Portfolio',
  //   href: '/portfolio',
  //   icon: <PortraitIcon />,
  // },
  // {
  //   title: 'Dashboard (example...)',
  //   href: '/dashboard',
  //   icon: <PieChartIcon />,
  // },
  // {
  //   title: '3D Viewer',
  //   href: '/threedview',
  //   icon: <WallpaperIcon />
  // },
  // {
  //   title: 'Users',
  //   href: '/users',
  //   icon: <PeopleIcon />
  // },
  // {
  //   title: 'Products',
  //   href: '/products',
  //   icon: <ShoppingBasketIcon />
  // },
  {
    title: 'Account',
    href: '/account',
    icon: <AccountBoxIcon />
  },
  // {
  //   title: 'Settings',
  //   href: '/settings',
  //   icon: <SettingsIcon />,
  // },
  // {
  //   title: 'Sign Out',
  //   href: '/sign-in',
  //   icon: <ExitToAppIcon />
  // }
];
