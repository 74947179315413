import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useQuery } from '@apollo/client';
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { AIAnalysisData } from 'client/graphql/query';
import fire from 'client/config/Fire';
import generatingReport from 'client/lotties/woman-generating-innovative-idea';
import {googleClickHandler} from 'client/utils/googlePatentsUrlConverter';

export default function AIAnlaysis () {
    const { data } = useQuery(AIAnalysisData);
    const { assignee, date, patentNumber } = data;

    const location = useLocation();
    let patid = location.pathname;
    patid = patid.replace('/chat/', '');
    const [analysis, setAnalysis] = useState([]);
    const [loading, setLoading] = useState(false);
    
    //const currentUser = fire.auth() ? fire.auth().currentUser : null; 
    //const email = currentUser ? currentUser.email : '';
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: generatingReport,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    useEffect(() => {
        const db = fire.firestore();

        // 'openai' 컬렉션을 구독합니다.

        const companyDocRef = db.collection('patNews').doc(assignee.replace(/\s+/g, '-').replace(/[^\w-]/gi, ''))
        const unsubscribe = companyDocRef.collection('patId').doc(patid)
          .onSnapshot((snapshot) => {
            
            const data = snapshot.data();
            const answer2 = data ? data.answer2 : undefined;
            setLoading(answer2 === undefined || !answer2); 

            // 상태를 업데이트하여 화면을 갱신합니다.
            setAnalysis(snapshot.data());
          });
    
        // 컴포넌트가 언마운트되면 구독을 취소합니다.
        return () => unsubscribe();
      }, [patid, assignee]);

    return (
        <Grid sx = {{padding: 15}}>
          {analysis && (
            <Grid>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h4" sx = {{fontFamily: 'Arial', fontWeight: 'bold' }}> 
                    {analysis.title}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title="Actual Patent Link">
                    <IconButton
                      onClick={(e) => googleClickHandler(data.patentNumber)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  
                </Grid>
              </Grid>
              
              <br/>
              <Typography sx = {{fontFamily: 'Arial', fontWeight: 'bold' }}>Assignee: {assignee}</Typography>
              <Typography sx = {{fontFamily: 'Arial', fontWeight: 'bold' }}>Patent Number: {patentNumber}</Typography>
              <Typography sx = {{fontFamily: 'Arial', fontWeight: 'bold' }}>Date: {date}</Typography>
              
              
              <br/>
              <br/>
              <Typography style={{ whiteSpace: 'pre-line' }} sx = {{fontFamily: "'Jua', sans-serif", marginTop: 10}}>{analysis.answer}</Typography>
              <Typography style={{ whiteSpace: 'pre-line' }} sx = {{fontFamily: "'Jua', sans-serif", marginTop: 10}}>{analysis.answer2}</Typography>
            </Grid>
          )}
          {loading && (
          <Grid>
            <Lottie 
              options={defaultOptions}
              height={400}
              width={400}
            />
            <Typography sx = {{fontFamily: 'Arial', fontWeight: 'bold' }}>Generating Report with GPT-4...</Typography>
            <Typography sx = {{fontFamily: 'Arial', fontWeight: 'bold' }}>It takes about 30 ~ 60s.</Typography>
          </Grid>)}
        </Grid>
    );
}

