import React from 'react';
import { useQuery } from '@apollo/client';
import { LinearProgress, Typography } from '@material-ui/core';
import { CPCByID } from 'client/graphql/query';
import CountByAssignee from './CountByAssignee';

function GetCPCsByID({ patentID }) {
  const { loading, error, data } = useQuery(CPCByID, {
    variables: { id: patentID },
  });
  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  let cpcStr = data.CPCByID.reduce((acc, val) => (acc += val + '|'), '');
  cpcStr = cpcStr.slice(0, -1);
  return (
    <>
      {!cpcStr && (
        <Typography>
          Currently cannot provide analysis for patents, which do not provide
          cpcs. Work will be done using other criteria.
        </Typography>
      )}
      {cpcStr && <CountByAssignee cpc={cpcStr} />}
    </>
  );
}

export default GetCPCsByID;
