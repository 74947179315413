import Rainbow from 'rainbowvis.js';

export const dynamicColor = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

// https://pinetools.com/gradient-generator
export const gradientColors = (startColor, endColor, size) => {
  var rainbow = new Rainbow();
  rainbow.setNumberRange(1, size);
  rainbow.setSpectrum(startColor, endColor);
  var arr = [];
  for (var i = 1; i <= size; i++) {
    var hexColour = rainbow.colourAt(i);
    arr.push('#' + hexColour);
  }
  return arr;
};

export const gcSuggestions = [
  {
    startColor: '#6fd7b8',
    endColor: '#eea8d0',
    description: 'light green to light pink'
  },
  {
    startColor: '#eea8d0',
    endColor: '#6fd7b8',
    description: 'light pink to light green'
  },
  {
    startColor: '#1d5242',
    endColor: '#cddddc',
    description: 'dark green to cyan'
  },
  {
    startColor: '#305248',
    endColor: '#f3ecf0',
    description: 'dark green to white'
  },
  {
    startColor: '#47d0a6',
    endColor: '#f3e17c',
    description: 'light green to light yellow'
  },
  {
    startColor: '#347BE9',
    endColor: '#8ECFEC',
    description: 'light blue to dark blue'
  }
];
