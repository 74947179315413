import React, { useState } from 'react';
import WordCloud from './WordCloud';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  NativeSelect,
  FormHelperText,
} from '@material-ui/core';
import useStyles from './WordCloudCompany.style';


export default function WordCloudCompany() {
    const classes = useStyles();
    const [state, setState] = useState({
      switch: false,
      company: '',
      wordCloudCount: 100,
    });
  
    const [sameCompany, setSameCompany] = useState([
      'LG Energy Solution|LG 에너지솔루션|엘지 에너지솔루션|엘지에너지솔루션|LG EnergySolution|LG에너지솔루션',
      '주식회사 엘지화학|LG Chemical Ltd.|LG Chemical Ltd|LG Chemical|LG화학|LG Chemical Limited|LG Chemical Ltd|LG Chemical|LG Chem, Ltd.|Lg Chem, Ltd.',
      '삼성에스디아이 주식회사|Samsung Sdi Co., Ltd.',
      'Samsung Electronics|삼성전자',
      'Lg Electronics|엘지전자',
      'Matsushita Electric|Panasonic',
    ]);
  
    const handleChange = (prop) => (event) => {
      setState({ ...state, [prop]: event.target.value });
    };
  
    const handleChangeCheck = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };
  
    const handleInputClick = () => {
      setSameCompany([...sameCompany, state.company]);
    };
  
    const handleMouseDown = (event) => {
      event.preventDefault();
    };
  
    return (
      <>
          <Typography variant='body2'>
            Top {state.wordCloudCount} companies with most patents (click company)
          </Typography>
          <FormControl>
            <NativeSelect
              value={state.wordCloudCount}
              onChange={handleChange('wordCloudCount')}
              inputProps={{
                name: 'state',
                id: 'value-helper',
              }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </NativeSelect>
            <FormHelperText>Number of companies</FormHelperText>
          </FormControl>
          <br />
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={state.switch}
                onChange={handleChangeCheck}
                name='switch'
                color='primary'
                size='small'
              />
            }
            label={
              <Typography variant='overline'>Edit same company list</Typography>
            }
            size='small'
          />
          <Grid style={{ marginBottom: '10px' }}>
            {state.switch &&
              sameCompany.map((x, index) => {
                return (
                  <div key={x}>
                    <Typography
                      style={{ display: 'inline-block' }}
                      variant='caption'
                    >
                      ({index + 1}) {x}
                    </Typography>
                  </div>
                );
              })}
            {state.switch && (
              <FormControl className={classes.margin}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Add same company
                </InputLabel>
                <Input
                  id='standard-adornment-password'
                  type='text'
                  name='company'
                  value={state.company}
                  onChange={handleChange('company')}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleInputClick}
                        onMouseDown={handleMouseDown}
                        size='small'
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
          </Grid>
          <WordCloud
            className={classes.wordCloud}
            sameCompany={sameCompany}
            wordCloudCount={state.wordCloudCount}
          />
        </>
    );
  }
  