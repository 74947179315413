import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer } from '@material-ui/core';
import { pageList } from 'client/definitions/Sidebar.define';
import { Profile, SidebarNav } from './components';
import useStyles from './Sidebar.style';

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor='left'
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile />
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={pageList} />

          {/* <UpgradePlan /> */}
        </div>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
