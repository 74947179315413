import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Pagination } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: theme.spacing(5)
  },
  image: {
    marginLeft: 50,
    marginRight: 50,
    height: 720,
    width: 480
  },
  pagination: {
    marginTop: 25,
    marginLeft: 220,
    marginBottom: -20,
  }
}));

const pageList = [
  'resume1.png',
  'resume2.png',
  // can be added - files from /doc/~
];

export default function Portfolio() {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    
    <Box className={classes.root} display='flex'>
      <Box m='auto'>
        <img alt='resume' className={classes.image} src={`/doc/${pageList[page-1]}`} />
        <Pagination className={classes.pagination} count={2} color="primary" onChange={handleChange}/>
      </Box>
    </Box>
  );
}