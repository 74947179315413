import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  tableMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.white, 0.25),
    '&:hover': {
      backgroundColor: alpha(theme.palette.white, 0.15)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '30%'
    }
  },
  searchIcon: {
    marginRight: -theme.spacing(2)
  },
  searchButton: {
    marginTop: 5,
  },
  
  wordCloud: {
    marginTop: '10px'
  },
  ranking: {
    height: 50,
    width: 'auto',
    objectFit: 'scale-down'
  },
  companies: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  company : {
    padding: theme.spacing(3)
  }
}));

export default useStyles;