import React, { useState, useEffect } from 'react';
import { Route, Routes as Switch, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import fire from 'client/config/Fire';
import { IsUserLoggedIn } from 'client/graphql/query';
import { LayoutWrapper } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  CPC as CPCView,
  Companies as CompaniesView,
  News as NewsView,
  Analysis as AnalysisView,
  ThreedViewer as ThreedView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  Portfolio as PortfolioView,
  Trends as TrendsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Ranking as RankingView,
  Chat as ChatView,
  FindPatent as FindPatentView,
} from './views';

const sideBarCandidates = [
  { component: NewsView, layout: MainLayout, path: '/news' },
  { component: CompaniesView, layout: MainLayout, path: '/companies' },
  { component: FindPatentView, layout: MainLayout, path: '/patent/*' },
  { component: ChatView, layout: MainLayout, path: '/chat/*' },
  { component: RankingView, layout: MainLayout, path: '/ranking' },
  { component: CPCView, layout: MainLayout, path: '/cpc/*' },
  {
    component: AnalysisView,
    layout: MainLayout,
    path: '/dashboard',
    exact: true,
  },
  {
    component: ThreedView,
    layout: MainLayout,
    path: '/threedview',
    exact: true,
  },
  {
    component: DashboardView,
    layout: MainLayout,
    path: '/dashboard',
    exact: true,
  },
  { component: UserListView, layout: MainLayout, path: '/users', exact: true },
  {
    component: ProductListView,
    layout: MainLayout,
    path: '/products',
    exact: true,
  },
  {
    component: TypographyView,
    layout: MainLayout,
    path: '/typography',
    exact: true,
  },
  { component: IconsView, layout: MainLayout, path: '/icons', exact: true },
  { component: AccountView, layout: MainLayout, path: '/account', exact: true },
  {
    component: SettingsView,
    layout: MainLayout,
    path: '/settings',
    exact: true,
  },
  {
    component: PortfolioView,
    layout: MainLayout,
    path: '/portfolio',
    exact: true,
  },
  {
    component: TrendsView,
    layout: MainLayout,
    path: '/trends',
    exact: true,
  },
  {
    component: NotFoundView,
    layout: MinimalLayout,
    path: '/not-found',
    exact: true,
  },
];

const Routes = () => {
  const { data } = useQuery(IsUserLoggedIn);
  const { isLoggedIn } = data;

  const [user, setUser] = useState(null);

  useEffect(() => {
    userHandler();
  }, [user]);

  const userHandler = () => {
    fire.auth().onAuthStateChanged(user => {
      setUser(user ? user : null);
    });
  };

  return (
    <Switch>
      <Route path="/" element={<Navigate to= "/companies" replace />} />

      <Route
          path="/sign-in"
          element={
              <LayoutWrapper
                  layout={MinimalLayout}
                  component={() => {
                      return isLoggedIn ? <Navigate to='/companies' replace/> : <SignInView replace/>;
                  }}
              />
          }
      />

      <Route
          path="/sign-up"
          element={
              <LayoutWrapper
                  layout={MinimalLayout}
                  component={SignUpView}
              />
          }
      />

      {sideBarCandidates.map(({ component, layout, path }) => {
          return (
              <Route
                  key={path}
                  path={path}
                  element={
                      <LayoutWrapper
                          layout={layout}
                          component={component}
                      />
                  }
              />
          );
      })}
    </Switch>
  );
};

export default Routes;
