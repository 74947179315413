import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';

import { FixedHeaderTable } from 'client/components';
import fire from 'client/config/Fire';
import useStyles from './NewsTable.style';

function NewsTable({ assignee }) {
    const classes = useStyles();
    // eslint-disable-next-line 
    //const [companies, setCompanies] = useState(new Set());
    const [news, setNews] = useState([]);
    //const [seletedCompany, setSelectedCompany] = useState('');
    useEffect(() => {
      const getNews = async () => {
        try {
          const querySnapshot = await fire.firestore().collection('patNews').get();

          if (querySnapshot.empty) {
            console.log('No documents found!');
          } else {
            await querySnapshot.forEach(async doc => {
              const subcollectionRef = fire.firestore().collection('patNews').doc(doc.id).collection('patId');
              const querySubColSnapshot = await subcollectionRef.get();
              querySubColSnapshot.forEach(subDoc => {
                const {title, assignee, date, language} = subDoc.data();
                const newsData = {id:subDoc.id, title, assignee, date, language};
                setNews(news => [...news, newsData]);
              });
              //setCompanies(prevCompanies => new Set(prevCompanies).add((doc.id).replace(/-/g, ' ')));
            });
            
            //await setSelectedCompany([...companies][0]);
          }
        } catch (error) {
          console.log("Error fetching data: ", error);
        }
      };

      getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <Grid className={classes.root} container spacing={4}>
      {/* {[...companies].map((company, index) => (
        <Grid key = {company+index} item xs={12}>
          <h4>{company}</h4>
        </Grid>
      ))} */}
        
      <Grid item xs={12}>
        <FixedHeaderTable rows={news}
        useCompetitorFunc = {false} topViewedComponent = {true} />
      </Grid>
    </Grid>
    );
  }

  export default NewsTable;