import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import fire from 'client/config/Fire';
import { TextField, Button, Box, CircularProgress } from '@material-ui/core';
import { Grid } from '@mui/material'
import AIAnlaysis from './AIAnalysis';
import { AIAnalysisData } from 'client/graphql/query';

// const USER_STYLE = {
//   padding: 10
// }

// const ROBO_STYLE = {
//   padding: 10,
//   backgroundColor: '#FFFFFF'
// }
const Chat = (useChat = false) => { // for now, useChat is not used
  const { data } = useQuery(AIAnalysisData);
  const { assignee, title, date } = data;
  //const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState(''); // 사용자의 질문을 저장하기 위한 상태
  const [loading, setLoading] = useState(false);

  const currentUser = fire.auth().currentUser;
  
  // useEffect(() => {
  //   const db = fire.firestore();
    
  //   // 'openai' 컬렉션을 구독합니다.
  //   const unsubscribe = db.collection('patAnalysis')
  //     .onSnapshot((snapshot) => {
  //       const newQuestions = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data()
  //       }));
        
  //       // 상태를 업데이트하여 화면을 갱신합니다.
  //       setQuestions(newQuestions);
  //       setLoading(false);
  //     });

  //   // 컴포넌트가 언마운트되면 구독을 취소합니다.
  //   return () => unsubscribe();
  // }, []);

  const submitQuestion = async (e) => {
    e.preventDefault();

    // Google Cloud Functions의 URL입니다.
    const functionUrl = `https://us-central1-${process.env.REACT_APP_GCP_PROJECT_ID}.cloudfunctions.net/function-hello-world2`;
    //const functionUrl = `http://localhost:8080/`;
    setLoading(true);
    // POST 요청을 보내서 질문을 서버로 전송합니다.
    try {
      const userid = currentUser.email;
  
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question, userid, language: "Korean", model: process.env.REACT_APP_OPENAI_GPT_MODEL, assignee, date, title }),
      });

      // 응답을 처리합니다. (예: 성공 메시지를 표시)
      if (response.ok) {
        console.log('Question submitted successfully');
      } else {
        console.log('Failed to submit question');
      }
    } catch (error) {
      console.error('There was a problem with the request', error);
    }

    // 질문 상태를 초기화하여 입력 필드를 비웁니다.
    setQuestion('');
  }
  return (
    <>
      <AIAnlaysis />
      <Grid>
        {/* {questions.map((question) => (
          <div key={question.id}>
              <Grid container
                    sx = {USER_STYLE}
                    key = {question.question}
              >
                <Grid item xs={3} >
                  {<Avatar src="/images/icons/approve-user.png"/>}
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    gutterBottom
                    variant="h5"
                  >
                    {question.question}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container
                    sx = {ROBO_STYLE}
                    key = {question.answer}
              >
                <Grid item xs={3} >
                  {<Avatar src="/images/icons/openai.png"/>}
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    gutterBottom
                    variant="h5"
                  >
                    {question.answer}
                  </Typography>
                </Grid>
              </Grid>
          </div>
        ))} */}
      </Grid>
      {loading && <CircularProgress size={24} />}  {/* Show progress bar if loading */}
      {!useChat && <Grid
        container
        direction="column"
        justifyContent="flex-end"
        style={{ minHeight: '50vh' }} // This will ensure the form is pushed to the bottom of the viewport
      >
        <Box mx={2} mb={2}>  {/* Add margin-bottom to keep TextField at a fixed distance from the bottom */}
          <form onSubmit={submitQuestion} noValidate autoComplete="off">
            <TextField
              id="outlined-basic" 
              label="Send a message" 
              variant="outlined"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              fullWidth
            />
            <Box mt={2}> {/* Add some top margin to the button */}
              <Button variant="contained" color="primary" type="submit">
                Generate
              </Button>
            </Box>
          </form>
        </Box>
        
      </Grid>}
    </>
  ); 
}

export default Chat;
