import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  LinearProgress
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { TitlesByCPCs } from 'client/graphql/query';
import capitalize from 'client/utils/stringutil';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function CPCInfo({ cpcCodes: cpcs }) {
  const { loading, error, data } = useQuery(TitlesByCPCs, {
    variables: { cpcs }
  });
  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  const titleData = data.titlesByCPCs;
  return titleData.map(({ symbol, title }) => {
    const techs = String(title).split(';');
    return (
      <div key={symbol}>
        <Typography variant='caption'>{symbol}</Typography>
        {techs.map(x => {
          const capitalizedStr = capitalize(x);
          return (
            <div key={capitalizedStr}>
              <Typography variant='caption'>{capitalizedStr}</Typography>
            </div>
          );
        })}
      </div>
    );
  });
}

CPCInfo.propTypes = {
  cpcCodes: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  cpcButton: {
    marginTop: '20px'
  }
}));

export default function CustomizedDialogs({ cpcCodes }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  let firstList = [];
  let secondList = [];
  cpcCodes.forEach(({ code, first }) => {
    if (first && !firstList.includes(code)) {
      firstList.push(code);
    } else if (!firstList.includes(code) && !secondList.includes(code)) {
      secondList.push(code);
    }
  });

  let firstCPCs = '';
  let secondCPCs = '';
  firstCPCs = firstList.reduce((acc, value) => (acc += `'${value}', `), '');
  secondCPCs = secondList.reduce((acc, value) => (acc += `'${value}', `), '');

  if (firstCPCs) {
    firstCPCs = firstCPCs.slice(0, -2);
  }
  if (secondCPCs) {
    secondCPCs = secondCPCs.slice(0, -2);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        key='cpcButton'
        className={classes.cpcButton}
        variant='contained'
        color='primary'
        size='small'
        startIcon={<LibraryBooksIcon />}
        onClick={handleClickOpen}
      >
        View Technologies
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          key='customized-dialog-title'
          variant='caption'
          onClose={handleClose}
        >
          Technologies in patent
        </DialogTitle>
        <DialogContent dividers>
          <Typography key='Primary Technologies' variant='body2'>
            Primary Technologies
          </Typography>
          {firstCPCs && <CPCInfo cpcCodes={firstCPCs} />}
          <br />
          <Typography key='Secondary Technologies' variant='body2'>
            Secondary Technologies
          </Typography>
          {secondCPCs && <CPCInfo cpcCodes={secondCPCs} />}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}