import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
  LinearProgress
} from '@material-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getName } from 'country-list';

import { dynamicColor } from 'client/utils/colorutil';
import { ApplicationsPerCountry as APCQuery } from 'client/graphql/query';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '250px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const APCChart = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { className, ...rest } = props;

  const { loading, error, data: apcData } = useQuery(APCQuery);
  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  const { applicationsPerCountry } = apcData;
  if (applicationsPerCountry === null) return;

  let cntList = [];
  let countryList = [];
  let backgroundColorList = [];
  applicationsPerCountry.forEach(x => {
    cntList.push(x.count);
    countryList.push(getName(x.country) || x.country);
    backgroundColorList.push(dynamicColor());
  });
  backgroundColorList = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#339da1',
    ...backgroundColorList
  ];

  const data = {
    datasets: [
      {
        data: cntList,
        backgroundColor: backgroundColorList,
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: countryList
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <IconButton size='small'>
            <RefreshIcon />
          </IconButton>
        }
        title='Applications Per Country'
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <Typography variant='h5'>Ranking</Typography>
        {countryList.slice(0, 5).map((country, index) => {
          return (
            <Typography key={country} variant='h6'>
              {index + 1}: {country}
            </Typography>
          );
        })}

        {/* <div className={classes.stats}>
          {countryCnt.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div> */}
      </CardContent>
    </Card>
  );
};

APCChart.propTypes = {
  className: PropTypes.string
};

export default APCChart;
