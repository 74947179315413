import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  company: {
    height: 50,
    width: 50,
   
  },
}));

export default useStyles;