import React from 'react';
import { useQuery } from '@apollo/client';
import { Typography, LinearProgress } from '@material-ui/core';

import { ApplicationsPerCountry as APCQuery } from '../../../../graphql/query';
import GoogleGeoChart from '../../../../components/GoogleGeoChart';

export default function ApplicationsPerCountry() {
  const { loading, error, data } = useQuery(APCQuery);

  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  let chartData = [['Country', 'Popularity']];
  data.applicationsPerCountry.forEach(({ country, count }) => {
    const countryIdx = chartData.findIndex(arr => {
      return arr.includes(country);
    });

    if (countryIdx === -1) {
      chartData.push([country, count]);
    } else {
      chartData[countryIdx][1] += count;
    }
  });

  return (
    <>
      <Typography variant='body2'>Patent filings per country</Typography>
      <GoogleGeoChart width={'100%'} height={'500px'} chartData={chartData} />
    </>
  );
}
