import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { persistCache } from 'apollo3-cache-persist';
import { Chart } from 'react-chartjs-2'
import { chartjs } from './helpers';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import fire from 'client/config/Fire';
import { getAnalytics, logEvent } from "firebase/analytics";

import { IsUserLoggedIn } from 'client/graphql/query';

import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

const cache = new InMemoryCache();
persistCache({
  cache,
  storage: window.localStorage,
}).then(() => {
  // Continue setting up Apollo Client as usual.
});


cache.writeQuery({
  query: IsUserLoggedIn,
  data: {
    isLoggedIn: false,
  },
});


//http://${process.env.MY_URL}/.netlify/functions/graphql
const client = new ApolloClient({
  cache,
  uri: '/.netlify/functions/graphql',
  resolvers: {
    Query: {
      getLocalValue: (_, args, { cache }) => {
        const value = cache.readQuery({ query: IsUserLoggedIn });
        return value;
      },
    },
  },
});

export default function App() {
  const analytics = getAnalytics(fire);
  logEvent(analytics, 'notification_received');
  
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}
