import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import validate from 'validate.js';
import fire from 'client/config/Fire';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';

import { Grid, Button, TextField, Typography, Link } from '@material-ui/core';
import { IsUserLoggedIn } from 'client/graphql/query';
import { Facebook as FacebookIcon, Google as GoogleIcon } from 'client/icons';
import useStyles from './SignIn.style';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};



const SignIn = () => {

  const classes = useStyles();
  const client = useApolloClient();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
   // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  function handlePopupSignIn(provider) {
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    
    signInWithPopup(fire.auth(), provider)
      .then((result) => {        
        navigate('/companies');
        client.writeQuery({
          query: IsUserLoggedIn,
          data: {
            isLoggedIn: true,
          },
        });
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = (event) => {
    
    event.preventDefault();
    const { email, password } = formState.values;
    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((u) => {
        navigate('/companies');
        client.writeQuery({
          query: IsUserLoggedIn,
          data: {
            isLoggedIn: true,
          },
        });
      })
      .catch((error) => {
        console.log(error)
        const { code, message } = error;
        const errors = {};
        if (code.includes('wrong-password')) {
          errors.password = ['Invalid password, please try again.'];
        } else if (code.includes('attempts')) {
          errors.password = [message];
        }

        setFormState((formState) => ({
          ...formState,
          errors: errors || {},
        }));
        console.log(error);
      });
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant='h1'>
                Draw insights by analyzing competitor's intellectual property.
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant='body1'>
                  Analyze IP
                </Typography>
                <Typography className={classes.bio} variant='body2'>
                  Software Engineer
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant='h2'>
                  Sign in
                </Typography>
               
                <Grid className={classes.socialButtons} container spacing={2}>
                  <Grid item>
                    <Button
                      color='primary'
                      size='large'
                      variant='contained'
                      onClick={() => handlePopupSignIn(new FacebookAuthProvider())}
                    >
                      <FacebookIcon className={classes.socialIcon} />
                      Login with Facebook
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size='large'
                      variant='contained'
                      onClick={() => handlePopupSignIn(new GoogleAuthProvider())}
                    >
                      <GoogleIcon className={classes.socialIcon} />
                      Login with Google
                    </Button>
                  </Grid>
                </Grid>
                <Typography
                  align='center'
                  className={classes.sugestion}
                  color='textSecondary'
                  variant='body1'
                >
                  Sign in with email address
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Sign in now
                </Button>
                <Typography color='textSecondary' variant='body1'>
                  Don't have an account?{' '}
                  <Link component={RouterLink} to='/sign-up' variant='h6'>
                    Sign up
                  </Link>
                </Typography>
                
                <Typography key='caption' variant='caption'>
                  Please contact analyzeintellectualproperty@gmail.com
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};


export default SignIn;
