import React, { useState } from 'react';

import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import {
  Grid,
  LinearProgress,
  FormControl,
  NativeSelect,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gradientColors, gcSuggestions } from 'client/utils/colorutil';
import { YearCountByAssignee } from 'client/graphql/query';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


export default function PatentBar({ assignee }) {
  const classes = useStyles();
  const [years, setYears] = useState(20);
  const { loading, error, data } = useQuery(YearCountByAssignee, {
    variables: { assignee },
  });

  if (loading) return <LinearProgress />;
  if (error) {
    console.log('ERROR', error);
    return <p>Error :(</p>;
  }

  let yearCount = data.yearCountByAssignee;
  yearCount = yearCount.slice(0, years);
  // increase sort
  yearCount.sort(function (a, b) {
    return a.year < b.year ? -1 : a.year > b.year ? 1 : 0;
  });

  const labelList = [];
  const countList = [];
  //let average = 0;
  yearCount.forEach(({ year, count }) => {
    labelList.push(year);
    countList.push(count);
    //average += count;
  });

  //average /= years;

  let backgroundColorList = [];
  const { startColor, endColor } = gcSuggestions[5];
  backgroundColorList = gradientColors(endColor, startColor, years);

  const barData = {
    labels: labelList,
    datasets: [
      {
        label: `${assignee} - # of patents`,
        backgroundColor: backgroundColorList,
        borderColor: '#6DCAE3',
        borderWidth: 1,
        hoverBackgroundColor: '#AED6F1',
        hoverBorderColor: '#3498DB',
        data: countList,
        
      },
    ],
  };

  const handleChange = (event) => {
    setYears(event.target.value);
  };

  return (
    <Grid>
      {/* <Typography variant='body2'>
        Patents on average by {assignee}: {Math.round(average * 100) / 100}
      </Typography> */}
      <FormControl className={classes.formControl}>
        <NativeSelect
          value={years}
          onChange={handleChange}
          inputProps={{
            name: 'value',
            id: 'value-helper',
          }}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </NativeSelect>
        <FormHelperText>Years of trends</FormHelperText>
      </FormControl>

      <Bar
        data={barData}
        options={{
          maintainAspectRatio: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          }
        }}
      />
    </Grid>
  );
}
