import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    drawer: {
      width: 240,
      [theme.breakpoints.up('md')]: {
        marginTop: 64,
        height: 'calc(100% - 64px)'
      }
    },
    root: {
      backgroundColor: theme.palette.red,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.spacing(2)
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
    nav: {
      backgroundColor: theme.palette.red,
      marginBottom: theme.spacing(2)
    },
    // upgrade: {
    //   position: 'absolute',
    //   bottom: theme.spacing(3),
    //   left: 0,
    //   right: 0,
    //   marginBottom: 0,
    // }
  }));

  export default useStyles