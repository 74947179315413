import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function FindPatent() {
    const navigate = useNavigate();
    const [text, setText] = React.useState('');
    const changeHandler = (e) => { setText(e.target.value); };
    const keyDownHandler = (e) => { if (e.keyCode === 13) { navigate(`/chat/${text}`); } };
    const onSearchClick = () => { navigate(`/chat/${text}`); };

    return (
        <Grid sx={{padding:3}}>
            <Grid xs={12}>
                <TextField id="outlined-basic" 
                  value={text}
                  label="Search Patent by ID" 
                  variant="outlined" 
                  onChange={(e) => changeHandler(e)}
                  onKeyDown={(e) => keyDownHandler(e)}/>
                <IconButton onClick={onSearchClick}>
                    <SearchIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}
export default FindPatent;