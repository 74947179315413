import React from 'react';
import { useQuery } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import countries from 'i18n-iso-countries';
import { APCByAssignee } from '../../../graphql/query';
import GoogleGeoChart from '../../../components/GoogleGeoChart';

const europeCodes = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  GB: 'United Kingdom'
};

export default function PatentChart({ assignee }) {
  const { loading, error, data } = useQuery(APCByAssignee, {
    variables: { assignee }
  });

  if (assignee === '') return null;
  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  let chartData = [['Country', 'Popularity']];

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

  data.APCByAssignee.forEach(({ country, count }) => {
    const countryIdx = chartData.findIndex(arr => {
      return arr.includes(country);
    });

    if (country === 'EP') {
      const europeCountryLength = Object.keys(europeCodes).length;
      Object.keys(europeCodes).forEach(key => {
        const keyIdx = chartData.findIndex(obj => {
          return obj.includes(key);
        });

        const rounded = Math.round(count / europeCountryLength);
        keyIdx === -1
          ? chartData.push([key, rounded])
          : (chartData[keyIdx][1] += rounded);
      });
    }

    if (countryIdx === -1) {
      chartData.push([country, count]);
    } else {
      chartData[countryIdx][1] += count;
    }
  });

  return (
    <GoogleGeoChart width={'100%'} height={'60vh'} chartData={chartData} />
  );
}
