import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Avatar
} from '@mui/material';

import { useApolloClient, useQuery } from '@apollo/client';
import {googleClickHandler, patentNumberConverter} from 'client/utils/googlePatentsUrlConverter';
import fire from 'client/config/Fire';
import firebase from 'firebase/compat/app'
import { updateDoc } from "firebase/firestore";

import { patentsTableDefinitions } from 'client/definitions/Patents.define';
import { Google as GoogleIcon } from 'client/icons';
import { AIAnalysisData, IsUserLoggedIn } from 'client/graphql/query';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


function FixedHeaderTable({ history, rows, useCompetitorFunc = true, topViewedComponent = false, language = 'English' }) {
  
  const { data } = useQuery(IsUserLoggedIn);
  const { isLoggedIn } = data;
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userUsedPatents, setUserUsedPatents] = useState(new Set());
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = fire.auth() ? fire.auth().currentUser : null; 
  const email = currentUser ? currentUser.email : '';
  const curLocation = location.pathname;
  const db = fire.firestore();
  const client = useApolloClient();
  
  useEffect(() => {
    
    // 'openai' 컬렉션을 구독합니다.
    const unsubscribe = db.collection('userAIAnalysis')
      .onSnapshot((snapshot) => {
        const docWithPatid = snapshot.docs.find(doc => doc.id === email);
        const response = docWithPatid ? docWithPatid.data() : null;
        if (response !== null && !topViewedComponent) {
          const keysSet = new Set(Object.keys(response));
          // eslint-disable-next-line no-unused-vars
          setUserUsedPatents(keysSet);
        }
      });

    // 컴포넌트가 언마운트되면 구독을 취소합니다.
    return () => unsubscribe();
  }, [db, email, useCompetitorFunc, topViewedComponent]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const competitorsClickHandler = (id) => {
    
    navigate(
      curLocation.replace('patents', 'cpc').replace('companies', 'cpc').replace('trends', 'cpc').replace('news', 'cpc') + `/${id}`
    );
  };

  async function logFunctionUsage(email, patid) {
    const functionRef = await db.collection('userAIAnalysis').doc(email);
    const doc = await functionRef.get();

    if (!doc.exists) {
      // 문서가 없는 경우 새로 생성
      await functionRef.set({
          [patid]: {
              usedAt: firebase.firestore.Timestamp.now()
          }
      });
    } else {
        // 문서가 있는 경우 데이터 업데이트
        await functionRef.update({
            [patid]: {
                usedAt: firebase.firestore.Timestamp.now()
            }
        });
    }
    
  }

  async function updateUserClick(id) {
    try {
      const userClickRef = db.collection('userAIAnalysis').doc("clicks");
      const userClickDoc = await userClickRef.get();

      if (userClickDoc.exists) {
        const currentValue = userClickDoc.data()[id] || 0;
        const newValue = currentValue + 1;
        await updateDoc(userClickRef, {[id]:newValue});
      } else {
        await userClickRef.set({[id]:1});
      }
    } catch (error) {

    }
  }
  const aiAnalysisClickHandler = async (id, assignee, title, date) => {
    const patid = patentNumberConverter(id);
    if (isLoggedIn) {
      logFunctionUsage(email, patid);
    } 
    
    updateUserClick(patid);

    const companyDocRef = await db.collection('patNews').doc(assignee.replace(/\s+/g, '-').replace(/[^\w-]/gi, ''))
    const patidData = await companyDocRef.collection('patId').doc(patid).get();

    navigate(
      curLocation.replace('companies', 'chat').replace('trends', 'chat').replace('news','chat') + `/${patid}`
    );
    
    client.writeQuery({
      query: AIAnalysisData,
      data: {
          title,
          assignee,
          patentNumber: id,
          date,
        },
    });
   

    if (!patidData.exists) {
           // Google Cloud Functions의 URL입니다.
     const functionUrl = `https://us-central1-${process.env.REACT_APP_GCP_PROJECT_ID}.cloudfunctions.net/function-scraper`;
     //const functionUrl = `http://localhost:8080/`;
     // POST 요청을 보내서 질문을 서버로 전송합니다.
     try {
      const userid = email;
      const response = await fetch(functionUrl, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({ patid, userid, language, model: process.env.REACT_APP_OPENAI_GPT_MODEL, assignee, date, title }),
      });
      
      // 응답을 처리합니다. (예: 성공 메시지를 표시)
      if (response.ok) {
          console.log('Question submitted successfully');
      } else {
          console.log('Failed to submit question');
      }
      } catch (error) {
        console.error('There was a problem with the request', error);
      }
     
    }

      
  };

  return (
    <Paper sx={{width: '100%'}}>
      <TableContainer sx={{maxHeight: 650}}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {patentsTableDefinitions.map((column, index) => {
                if (!useCompetitorFunc && column.id === 'cpcCode') {
                  return null;
                }
                if (!topViewedComponent && column.id === 'language') {
                  return null;
                }
                return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth, backgroundColor: '#f5f5f5' }}
                >
                  {column.label}
                </TableCell>
              )})}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
           
                
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    key={row.id + index}
                    tabIndex={-1}
                    sx ={{backgroundColor : userUsedPatents.has(patentNumberConverter(row['id'])) ? '#e6f2ff': ''}}
                  >
                    {
                    patentsTableDefinitions.map((column) => {
                      const value = row[column.id];
                      if (!useCompetitorFunc && column.id === 'cpcCode') {
                        return null;
                      }
                      if (!topViewedComponent && column.id === "language") {
                        return null;
                      }
                      return (
                        <TableCell key={column.id} align={column.align} sx={{backgroundColor: (userUsedPatents[`'${patentNumberConverter(row['id'])}'`] ? 'yellow' : '')}}>
                          {column.id === 'ai-analysis' && (
                            <IconButton
                              onClick={(e) => aiAnalysisClickHandler(row['id'], row['assignee'], row['title'], row['date'])}
                            >
                              <Avatar sx={{width: 25, height: 25}} src="/images/icons/openai.png"/>
                            </IconButton>
                          )}
                          {column.id === 'action' && (
                            <IconButton
                              onClick={(e) => googleClickHandler(row['id'])}
                            >
                              <GoogleIcon />
                            </IconButton>
                          )}
                          {useCompetitorFunc && column.id === 'cpcCode' && (
                            <IconButton
                              onClick={(e) =>
                                competitorsClickHandler(row['id'])
                              }
                            >
                              <PeopleAltIcon />
                            </IconButton>
                          )}
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}


export default FixedHeaderTable;
