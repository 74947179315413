import 'd3-transition';
import { select } from 'd3-selection';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import ReactWordcloud from 'react-wordcloud';
import { Resizable } from 're-resizable';
import { LinearProgress } from '@material-ui/core';

import { AssigneeCnt } from 'client/graphql/query';

const resizeStyle = {
  border: 'solid 1px #ddd',
  fontWeight: 'bold',
  fontFamily: 'helvetica',
  background: '#f0f0f0'
};

const options = {
  enableTooltip: true,
  deterministic: true,
  fontFamily: 'Sen, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'bold',
  padding: 5,
  rotations: 1,
  rotationAngles: [0],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000
};

function WordCloud({ sameCompany, wordCloudCount }) {
  const navigate = useNavigate();

  const getCallback = callback => {
    return function(word, event) {
      const isActive = callback !== 'onWordMouseOut';
      const element = event.target;
      const text = select(element);
      text
        .on('click', () => {
          if (isActive) {
            navigate(`/companies?company=${word.text}`);
          }
        })
        .transition()
        .attr('background', 'white');
    };
  };

  const callbacks = {
    getWordTooltip: word => `"${word.text}" filed ${word.value} patents.`,
    onWordClick: getCallback('onWordClick'),
    onWordMouseOut: getCallback('onWordMouseOut'),
    onWordMouseOver: getCallback('onWordMouseOver')
  };

  const { loading, error, data } = useQuery(AssigneeCnt);
  if (loading) return <LinearProgress />;
  if (error) return <p>Error :(</p>;

  const words = [];
  data.assigneeCnt.forEach(({ assignee, count }) => {
    const sameAssigneeIdx = sameCompany.findIndex(company => {
      const candidates = company.split('|');
      return candidates.some(x => {
        const text1 = x.replace(/ /gi, '');
        const text2 = assignee.replace(/ /gi, '');
        const longer = text1.length > text2.length ? text1 : text2;
        const shorter = text1.length > text2.length ? text2 : text1;
        return longer.includes(shorter);
      });
    });

    if (sameAssigneeIdx !== -1) {
      const sameAssignee = sameCompany[sameAssigneeIdx];
      const wordIdx = words.findIndex(word => word.text.includes(sameAssignee));
      if (wordIdx !== -1) {
        words[wordIdx].value += count;
      } else {
        words.push({ text: sameAssignee, value: count });
      }
    } else {
      const idx = words.findIndex(word => {
        const wordReplaced = word.text.replace(/ /gi, '');
        const assigneeReplaced = assignee.replace(/ /gi, '');
        const longer =
          wordReplaced > assigneeReplaced ? wordReplaced : assigneeReplaced;
        const shorter =
          wordReplaced > assigneeReplaced ? assigneeReplaced : wordReplaced;
        return longer.includes(shorter);
      });

      if (idx !== -1) {
        const longerText =
          words[idx].text.length > assignee.length ? words[idx].text : assignee;
        words[idx].text = longerText;
        words[idx].value += count;
      } else {
        words.push({ text: assignee, value: count });
      }
    }
  });

  return (
    <Resizable style={resizeStyle}>
      <ReactWordcloud
        options={options}
        callbacks={callbacks}
        words={words.slice(0, wordCloudCount)}
      />
    </Resizable>
  );
}

export default WordCloud;
