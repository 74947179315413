function googlePatentsUrlConverter(patentNumber) {
    const arr = patentNumber.split('-');
    // exceptional case handling
    if (arr.includes('US') && arr.includes('A1')) {
      arr[1] = arr[1].slice(0, 4) + '0' + arr[1].slice(4);
    }
    
    patentNumber = arr.join('');

    if (patentNumber[patentNumber.length - 1].toLowerCase() === 's') {
        patentNumber = patentNumber.slice(0, -1);
    }
    const url = `https://patents.google.com/patent/${patentNumber}`.replace(
        /-/gi,
        ''
    );

    return url;
}

function patentNumberConverter(patentNumber) {
    const arr = patentNumber.split('-');
    // exceptional case handling
    if (arr.includes('US') && arr.includes('A1')) {
      arr[1] = arr[1].slice(0, 4) + '0' + arr[1].slice(4);
    }
    patentNumber = arr.join('');

    if (patentNumber[patentNumber.length - 1].toLowerCase() === 's') {
        patentNumber = patentNumber.slice(0, -1);
    }
   
    return patentNumber;
}

function googleClickHandler(patentNumber) {
    const convertedUrl = googlePatentsUrlConverter(patentNumber);

    const win = window.open(convertedUrl, '_blank');
    if (win != null) {
        win.focus();
    }
}

export {googleClickHandler, googlePatentsUrlConverter, patentNumberConverter};