import React from 'react'
import { IconButton, CardMedia } from '@material-ui/core';
import useStyles from './CompanyIcon.style';

const CompanyIcon = props => {
    const classes = useStyles();
    
    const {image, onClick} = props
    
    return (
        <IconButton onClick={onClick}>
            <CardMedia className = {classes.company} component="img" alt="ranking" image={image} title="Click Logo for patents analysis"/>
        </IconButton>
    );
} 

export default CompanyIcon