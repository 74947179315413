import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(5) },
  cpc: { minHeight: '60vh' },

  searchButton: { marginTop: '20px' },
  infoGrid: { marginTop: '50px' }
}));

export default useStyles;
