import React from 'react';

import {
  Grid,
} from '@material-ui/core';
import NewsTable from './components/NewsTable/NewsTable';

function News() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <NewsTable />
      </Grid>
    </Grid>
  );
}

export default News;
