import React from 'react';
import PropTypes from 'prop-types';

const LayoutWrapper = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Layout {...rest}>
      <Component />
    </Layout>
  );
};

LayoutWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
};

export default LayoutWrapper;