import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  ranking: {
    height: 25,
    width: 'auto',
    objectFit: 'scale-down'
  },
}));

export default useStyles;