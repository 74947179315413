import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography,
  Menu,
  MenuItem,
 
  Tooltip
} from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

import useStyles from './Topbar.style';
import fire from 'client/config/Fire';
import { IsUserLoggedIn } from 'client/graphql/query';

const Topbar = props => {
  const { className, onSidebarOpen, staticContext, ...rest } = props;

  const classes = useStyles();
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications] = useState([]);
  const { data } = useQuery(IsUserLoggedIn);
  const { isLoggedIn } = data;
  const navigate = useNavigate();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    fire
      .auth()
      .signOut()
      .then(() => {
        client.writeQuery({
          query: IsUserLoggedIn,
          data: {
            isLoggedIn: false
          }
        });
        navigate('/sign-in');
      });

    handleClose();
  };
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Typography style={{ color: 'white' }} variant='h5' noWrap>
          Patents Analysis
        </Typography>

        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color='inherit'>
            <Badge
              overlap="rectangular"
              badgeContent={notifications.length}
              color='primary'
              variant='dot'
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {
            isLoggedIn ? <IconButton
            className={classes.signOutButton}
            color='inherit'
            onClick={handleClick}
          >
            <AccountCircleIcon />
          </IconButton> : 
          <Button variant='contained' onClick={() => navigate('/sign-in')}>Sign In</Button>
          }
          
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
          </Menu>
        </Hidden>
        <Hidden lgUp>
          <Tooltip title='Sign Out'>
            <IconButton color='inherit' onClick={handleLogOut}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
          <IconButton color='inherit' onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
