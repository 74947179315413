import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { ViewTypeEnum } from '../resources/enum';

const useStyles = makeStyles({
  tableIcon: props => ({
    color: props.viewType === ViewTypeEnum.TABLE ? 'blue' : 'dimgrey'
  }),
  tileIcon: props => ({
    color: props.viewType === ViewTypeEnum.TILE ? 'blue' : 'dimgrey'
  })
});

export default function TableTileView() {
  const [viewType, setViewType] = useState(ViewTypeEnum.TABLE);
  const classes = useStyles({ viewType });
  return (
    <>
      <IconButton className={classes.tableIcon}>
        <ListIcon
          onClick={() => {
            setViewType(ViewTypeEnum.TABLE);
          }}
        />
      </IconButton>
      <IconButton className={classes.tileIcon}>
        <ViewWeekIcon
          onClick={() => {
            setViewType(ViewTypeEnum.TILE);
          }}
        />
      </IconButton>
    </>
  );
}
