import { gql } from '@apollo/client';


const News = gql`
  query news($assignee: String!) {
    news(assignee: $assignee) {
      id
      country
      assignee
      date
      title
      title_language
    }
  }
`;

const Patents = gql`
  query patents($assignee: String!) {
    patents(assignee: $assignee) {
      id
      country
      assignee
      date
      title
      title_language
    }
  }
`;

const ApplicationsPerCountry = gql`
  {
    applicationsPerCountry {
      country
      count
    }
  }
`;

const APCByAssignee = gql`
  query APCByAssignee($assignee: String!) {
    APCByAssignee(assignee: $assignee) {
      country
      count
    }
  }
`;

const AssigneeCnt = gql`
  {
    assigneeCnt {
      assignee
      count
    }
  }
`;

const AssigneeCntByCPC = gql`
  query assigneeCntByCPC($cpc: String!) {
    assigneeCntByCPC(cpc: $cpc) {
      assignee
      count
    }
  }
`;

const CPCByID = gql`
  query CPCByID($id: String!) {
    CPCByID(id: $id)
  }
`;

const infoByCPC = gql`
  query infoByCPC($id: String!) {
    infoByCPC(id: $id) {
      country_code
      publication_date
      assignee
      inventor
      title
      cpc {
        code
        inventive
        first
        tree
      }
    }
  }
`;

const TitlesByCPCs = gql`
  query titlesByCPCs($cpcs: String!) {
    titlesByCPCs(cpcs: $cpcs) {
      symbol
      title
    }
  }
`;

const YearCountByAssignee = gql`
  query yearCountByAssignee($assignee: String!) {
    yearCountByAssignee(assignee: $assignee) {
      year
      count
    }
  }
`;

const ScrapePatInfo = gql`
  query scrapePatInfo($id: String!) {
    scrapePatInfo(id: $id) {
      abstract
      background
      summary
      claims
    }
  }
`;

const IsUserLoggedIn = gql`
  query {
    isLoggedIn @client
  }
`;


const AIAnalysisData = gql`
  query {
    title @client
    assignee @client
    patentNumber @client
    date @client
  }
`;

export {
  News,
  Patents,
  ApplicationsPerCountry,
  APCByAssignee,
  AssigneeCnt,
  AssigneeCntByCPC,
  CPCByID,
  infoByCPC,
  TitlesByCPCs,
  YearCountByAssignee,
  ScrapePatInfo,
  IsUserLoggedIn,
  AIAnalysisData
};
