import React, { Fragment } from 'react';
import { Grid, Typography as MuiTypography } from '@material-ui/core';
import useStyles from './Typography.style'
import variants from './variants'


const Typography = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {Object.keys(variants).map((key, i) => (
          <Fragment key={i}>
            <Grid item sm={3} xs={12}>
              <MuiTypography variant='caption'>{key}</MuiTypography>
            </Grid>
            <Grid item sm={9} xs={12}>
              <MuiTypography variant={key}>{variants[key]}</MuiTypography>
            </Grid>
          </Fragment>
        ))}
      </Grid> 
    </div>
  );
};

export default Typography;
