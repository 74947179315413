export const patentsTableDefinitions = [
  {
    id: 'ai-analysis',
    label: 'AI Analysis',
    minWidth: 10,
    align: 'left',
    format: value => value.toLocaleString()
  },
  {
    id: 'action',
    label: 'Find Patents',
    minWidth: 20,
    align: 'left',
    format: value => value.toLocaleString()
  },
  {
    id: 'cpcCode',
    label: 'Find Competitors',
    minWidth: 20,
    align: 'left'
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 40,
    align: 'left',
    format: value => value.toLocaleString()
  },
  {
    id: 'assignee',
    label: 'Assignee',
    minWidth: 170,
    align: 'left',
    format: value => value.toLocaleString()
  },
  {
    id: 'language',
    label: 'Lang',
    minWidth: 100,
    align: 'left',
    format: value => value.toLocaleString()
  },
  {
    id: 'id',
    label: 'Patent Number',
    minWidth: 50,
    align: 'left',
    format: value => value.toLocaleString()
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 40,
    align: 'left'
  }
];
